import React, { useState, useEffect } from "react";
import "../styles/FindTeam.css";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import { BASE_PATH } from "../utils/constants";
import FilterDropdown from "../components/FilterDropdown";
import { fetchUserProfile } from "../authUtils";
import config from "../config";
import {
  useAddConnectionMutation,
  useFindTeamQuery,
  useListConnectionsQuery,
  useStartConversationMutation,
} from "../utils/APIUtil";
import { toast } from "react-toastify";
import { useCurrentUser } from "../utils/APIHooks";

function FindTeam({ isChatExpanded }) {
  // const [userId, setUserId] = useState(null);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [
    startConversation,
    {
      isSuccess: isStartConversationSuccess,
      data: newConversationData,
      error: startConversationError,
      isLoading: isStartConversationLoading,
    },
  ] = useStartConversationMutation();
  const [
    addConnection,
    { isSuccess: isAddConnectionSuccess, isLoading: isAddConnectionLoading },
  ] = useAddConnectionMutation();
  const currentUser = useCurrentUser();
  const { data: connectionsData } = useListConnectionsQuery(undefined, {
    skip: currentUser == null,
  });
  const { data: findTeamData, isLoading: isFindTeamLoading } =
    useFindTeamQuery();

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const handleToggleDropdown = (dropdown) => {
    console.log(dropdown);
    setActiveDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

 const sendBrevoEmail = async (recipientId) => {
  try {
    const recipientProfile = await fetchUserProfile(recipientId);
    if (recipientProfile?.email) {
      try {
        const response = await fetch("https://api.brevo.com/v3/smtp/email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "accept": "application/json",
            "api-key": config.brevo.apiKey,
          },
          body: JSON.stringify({
            sender: { name: "PressPlay Notifications", email: "hello@pressplay.gg" },
            to: [{ email: recipientProfile.email }],
            templateId: 1
          }),
        });
    
        if (!response.ok) throw new Error("Failed to send email");
      } catch (error) {
        console.error("Error sending Brevo email:", error);
      }
    } else {
      console.warn("⚠️ No email found for recipient, skipping Brevo email.");
    }
  } catch (error) {
    console.error("❌ Error sending Brevo email:", error);
  }
};


  const handleRemoveFilter = (filterToRemove) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = prevFilters.filter(
        (filter) =>
          filter.category !== filterToRemove.category ||
          filter.option !== filterToRemove.option
      );

      const filtered = profiles.filter((profile) => {
        const groupedFilters = updatedFilters.reduce((acc, filter) => {
          acc[filter.category] = acc[filter.category] || [];
          acc[filter.category].push(filter.option);
          return acc;
        }, {});

        return Object.entries(groupedFilters).every(([category, options]) => {
          const fieldMapping = {
            Specialty: profile.specialty,
            Location: profile.location,
            Tools: profile.tools,
            Experience: profile.experience,
          };
          if (category === "Tools") {
            return options.every((option) =>
              fieldMapping[category]?.includes(option)
            );
          }

          return options.some((option) =>
            fieldMapping[category]?.includes(option)
          );
        });
      });

      setFilteredProfiles(filtered);
      return updatedFilters;
    });
  };

  const handleAddFilter = (category, option) => {
    setSelectedFilters((prevFilters) => {
      const isDuplicate = prevFilters.some(
        (filter) => filter.category === category && filter.option === option
      );

      if (isDuplicate) {
        return prevFilters;
      }

      const updatedFilters = [...prevFilters, { category, option }];

      const groupedFilters = updatedFilters.reduce((acc, filter) => {
        acc[filter.category] = acc[filter.category] || [];
        acc[filter.category].push(filter.option);
        return acc;
      }, {});

      const matchesFilters = (profile) => {
        return Object.entries(groupedFilters).every(([category, options]) => {
          const fieldMapping = {
            Specialty: profile.specialty,
            Location: profile.location,
            Tools: profile.tools,
            Experience: profile.experience,
          };

          if (category === "Tools") {
            return options.every((option) =>
              fieldMapping[category]?.includes(option)
            );
          }

          return options.some((option) =>
            fieldMapping[category]?.includes(option)
          );
        });
      };

      const filtered = profiles.filter(matchesFilters);

      setFilteredProfiles(filtered);
      return updatedFilters;
    });
  };

  useEffect(() => {
    if (newConversationData) {
      sendBrevoEmail(selectedProfile.id);
      closePopup();
    }
  }, [newConversationData]);
  

  useEffect(() => {
    if (isAddConnectionSuccess) {
      alert(`${selectedProfile.name} has been added to your connections.`);
    }
  }, [isAddConnectionSuccess]);

  const isConnected = (profileId) => {
    if (!connectionsData) return false;
    return connectionsData.connections.map((c) => c.id).includes(profileId);
  };

  useEffect(() => {
    if (findTeamData) {
      setProfiles(findTeamData.profiles);
      setFilteredProfiles(findTeamData.profiles);
    }
  }, [findTeamData]);

  useEffect(() => {
    if (startConversationError) {
      toast(startConversationError.data?.error, {
        position: "top-left",
        className: "toast-message",
      });
    }
  }, [startConversationError]);

  const openPopup = (profile) => {
    setSelectedProfile(profile);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedProfile(null);
  };

  return (
    <>
      {showAuthPopup && (
        <div className="auth-popup-overlay">
          <div className="auth-popup">
            <h2>Find Your Team</h2>
            <p>
              To protect the privacy of our users you need to be logged in to
              access this page.
            </p>
            <div className="auth-popup-buttons">
              <Link to={`${BASE_PATH}/login`} className="auth-button">
                Log In
              </Link>
              <Link to={`${BASE_PATH}/sign-up`} className="auth-button">
                Sign Up Free
              </Link>
            </div>
          </div>
        </div>
      )}

      <div className={`find-team ${showAuthPopup ? "blurred" : ""}`}>
        <h1>Find a Team</h1>
        <button className="toggle-filters-button" onClick={toggleFilters}>
          {filtersVisible ? "Hide Filters" : "Show Filters"}
        </button>

        {filtersVisible && (
          <div className="filters">
            <FilterDropdown
              options={[
                "Development",
                "Design",
                "Project Management",
                "QA Testing",
                "Mobile",
                "Narrative",
                "2D Art",
                "3D Art",
                "Level Design",
                "Character Design",
                "Animation",
                "Sound Design",
                "Soundtrack & Scoring",
                "AI",
                "VR/AR",
              ]}
              onSelect={(option) => handleAddFilter("Specialty", option)}
              isOpen={activeDropdown === "specialty"}
              onToggle={() => handleToggleDropdown("specialty")}
              filterOption={"Specialty"}
            />
            <FilterDropdown
              options={[
                "USA",
                "Canada",
                "UK",
                "Germany",
                "France",
                "Sweden",
                "Finland",
                "Denmark",
                "Japan",
                "South Korea",
                "Australia",
                "New Zealand",
                "Poland",
                "Netherlands",
                "Spain",
                "Italy",
                "Czech Republic",
                "Austria",
                "Brazil",
                "Mexico",
                "Argentina",
                "Singapore",
                "India",
                "Ireland",
                "Belgium",
                "Switzerland",
                "Norway",
              ]}
              onSelect={(option) => handleAddFilter("Location", option)}
              isOpen={activeDropdown === "location"}
              onToggle={() => handleToggleDropdown("location")}
              filterOption={"Location"}
            />
            <FilterDropdown
              options={[
                "Hobbyist",
                "Indie",
                "AAA",
                "OG",
                "Award Winner",
                "Game Jams",
                "Shipped Games",
              ]}
              onSelect={(option) => handleAddFilter("Experience", option)}
              isOpen={activeDropdown === "experience"}
              onToggle={() => handleToggleDropdown("experience")}
              filterOption={"Experience"}
            />
            <FilterDropdown
              options={[
                "Unreal Engine",
                "Unity",
                "Godot",
                "CryEngine, RPG Maker",
                "GameMaker Studio",
                "Blender",
                "Maya",
                "3ds Max",
                "ZBrush",
              ]}
              onSelect={(option) => handleAddFilter("Tools", option)}
              isOpen={activeDropdown === "tools"}
              onToggle={() => handleToggleDropdown("tools")}
              filterOption={"Tools"}
            />
          </div>
        )}

        {filtersVisible && (
          <div className="bubbles-container-team">
            {selectedFilters.map((filter, index) => (
              <div
                key={index}
                className={`bubble category-${filter.category.toLowerCase()}`}
              >
                {filter.option}
                <button
                  className="close-btn"
                  onClick={() => handleRemoveFilter(filter)}
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
        )}

        {isFindTeamLoading ? (
          <Spinner size="large" message="Fetching profiles..." />
        ) : (
          <div
            className={`profile-grid ${isChatExpanded ? "chat-expanded" : ""}`}
          >
            {filteredProfiles.map((profile) => (
              <div key={profile.id} className="profile-card">
                <div
                  className={`profile-card__image-container ${
                    isConnected(profile.id) ? "connected" : ""
                  }`}
                >
                  <img
                    src={profile.imageUrl}
                    alt={profile.name}
                    className="profile-card__image"
                  />
                </div>
                <h3 className="profile-card__name"> {profile.name.length > 20 ? profile.name.substring(0, 20) + "..." : profile.name}</h3>
                <p className="profile-card__specialty">
                {profile.specialty.length > 30 ? profile.specialty.substring(0, 30) + "..." : profile.specialty}
                </p>
                <p className="profile-card__location">📍  {profile.location.length > 30 ? profile.location.substring(0, 30) + "..." : profile.location}</p>
                <p className="profile-card__goals">
                  {profile.goals.length > 70 ? profile.goals.substring(0, 70) + "..." : profile.goals}
                </p>
                <button
                  className="quick-view-button"
                  onClick={() => openPopup(profile)}
                >
                  Quick View
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      {showPopup && selectedProfile && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-card" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closePopup}>
              X
            </button>
            <div className="profile-picture-container">
              <img
                src={selectedProfile.imageUrl}
                alt={selectedProfile.name}
                className="profile-picture"
              />
            </div>
            <h3 className="popup-card__name">{selectedProfile.name}</h3>
            {selectedProfile.specialty && (
              <p>
                <strong>Specialty</strong> {selectedProfile.specialty}
              </p>
            )}
            {selectedProfile.location && (
              <p>
                <strong>Location</strong> {selectedProfile.location}
              </p>
            )}
            {selectedProfile.goals && (
              <p>
                <strong>Bio</strong> {selectedProfile.goals}
              </p>
            )}
            {selectedProfile.lookingFor && (
              <p>
                <strong>Who I want to work with</strong>{" "}
                {selectedProfile.lookingFor}
              </p>
            )}
            {selectedProfile.whatIBring && (
              <p>
                <strong>What I bring</strong> {selectedProfile.whatIBring}
              </p>
            )}
            {selectedProfile.experience && (
              <p>
                <strong>Experience</strong> {selectedProfile.experience}
              </p>
            )}
            {selectedProfile.hoursAvailable && (
              <p>
                <strong>Time</strong> {selectedProfile.hoursAvailable}
              </p>
            )}
            {isConnected(selectedProfile.id) ? (
              <button
                className="send-message-button"
                onClick={() => {
                  if (!selectedProfile) {
                    alert("No connection selected");
                    return;
                  }
                  startConversation({
                    recipientId: selectedProfile.id,
                    initialMessage: `Hi ${selectedProfile.name}, let's connect!`,
                  });
                }}
                disabled={isStartConversationLoading}
              >
                Send Message
              </button>
            ) : (
              <button
                className="add-to-connections-button"
                onClick={() => {
                  addConnection(selectedProfile.id);
                }}
                disabled={isAddConnectionLoading}
              >
                + Add to Connections
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default FindTeam;
