import React, { useState, useEffect, useMemo } from "react";
import AWS from "aws-sdk";
import { useSearchParams } from "react-router-dom"
import { FiEdit } from "react-icons/fi";
import "../styles/EditProfile.css";
import Spinner from "../components/Spinner";
import config from "../config";
import FilterDropdown from "../components/FilterDropdown";
import { checkProfileCompletion } from "../utils/util";
import { useCurrentUser } from "../utils/APIHooks";
import { fetchUserProfile } from "../authUtils";

import {
  useListConnectionsQuery,
  useRemoveConnectionMutation,
  useStartConversationMutation,
  useUpdateProfileMutation,
} from "../utils/APIUtil";
import { toast } from "react-toastify";
import DefaultUserImage from "../imgs/DefaultUserImage.jpg";

const ProfileCompletionBanner = ({ isComplete }) => {
  if (isComplete) return null;
  return (
    <div className="text-center rounded py-2 bg-red-300">
      <b>Almost done,</b> but your profile is <b>incomplete!</b> Answer a few questions to <b>find your team :D</b>!
    </div>
  );
};

const IncompleteSectionBadge = () => (
  <div className="bg-red-300 px-2 rounded-full">Missing</div>
);

function EditProfile() {
  const [userId, setUserId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [acctEditMode, setAcctEditMode] = useState(false);
  const [name, setName] = useState("");
  const [searchParams] = useSearchParams(); 
  const [bio, setBio] = useState("");
  const [lookingFor, setLookingFor] = useState("");
  const [whatIBring, setWhatIBring] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthday, setBirthday] = useState("");
  const [experience, setExperience] = useState("");
  const [location, setLocation] = useState("");
  const [skills, setSkills] = useState("");
  const [tools, setTools] = useState("");
  const [hoursAvailable, setHoursAvailable] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [updatedPhotoUrl, setUpdatedPhotoUrl] = useState("");
  const [file, setFile] = useState(null);
  const [activeTab, setActiveTab] = useState("Profile");
  const [previewUrl, setPreviewUrl] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [userConnections, setUserConnections] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [saveError, setSaveError] = useState("");
  const [city, setCity] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [country, setCountry] = useState("");
  const [
    updateProfile,
    { isSuccess: isUpdateProfileSuccess, isLoading: isUpdateProfileLoading },
  ] = useUpdateProfileMutation();

  const currentUser = useCurrentUser();
  const { data: connectionsData } = useListConnectionsQuery(undefined, {
    skip: currentUser == null,
  });
  const [
    startConversation,
    {
      isSuccess: isStartConversationSuccess,
      data: newConversationData,
      error: startConversationError,
      isLoading: isStartConversationLoading,
    },
  ] = useStartConversationMutation();
  const [
    removeConnection,
    {
      isSuccess: isRemoveConnectionSuccess,
      isLoading: isRemoveConnectionLoading,
    },
  ] = useRemoveConnectionMutation();

  const isProfileComplete = useMemo(() => {
    return checkProfileCompletion({
      photoUrl,
      name,
      bio,
      skills,
      experience,
      tools,
      whatIBring,
    });
  }, [photoUrl, name, bio, skills, experience, tools, whatIBring]);

  const [notificationSettings, setNotificationSettings] = useState({
    emailNotifications: false,
    pushNotifications: false,
    messageNotifications: false,
    newUserNotifications: false,
  });

  const backgroundImages = Array.from(
    { length: 20 },
    (_, i) => `https://picsum.photos/800/1600?random=${i}`
  );

  const sendBrevoEmail = async (recipientId) => {
    try {
      const recipientProfile = await fetchUserProfile(recipientId);
      if (recipientProfile?.email) {
        try {
          const response = await fetch("https://api.brevo.com/v3/smtp/email", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "accept": "application/json",
              "api-key": config.brevo.apiKey,
            },
            body: JSON.stringify({
              sender: { name: "PressPlay Notifications", email: "hello@pressplay.gg" },
              to: [{ email: recipientProfile.email }],
              templateId: 1
            }),
          });
      
          if (!response.ok) throw new Error("Failed to send email");
        } catch (error) {
          console.error("Error sending Brevo email:", error);
        }
      } else {
        console.warn("⚠️ No email found for recipient, skipping Brevo email.");
      }
    } catch (error) {
      console.error("❌ Error sending Brevo email:", error);
    }
  };

  const handleAddSelection = (category, option) => {
    if (category === "Skills" && !selectedSkills.includes(option)) {
      setSelectedSkills([...selectedSkills, option]);
    } else if (category === "Tools" && !selectedTools.includes(option)) {
      setSelectedTools([...selectedTools, option]);
    } else if (
      category === "Experience" &&
      !selectedExperience.includes(option)
    ) {
      setSelectedExperience([...selectedExperience, option]);
    }
  };

  const handleRemoveSelection = (category, option) => {
    if (category === "Skills") {
      setSelectedSkills(selectedSkills.filter((skill) => skill !== option));
    } else if (category === "Tools") {
      setSelectedTools(selectedTools.filter((tool) => tool !== option));
    } else if (category === "Experience") {
      setSelectedExperience(
        selectedExperience.filter((experience) => experience !== option)
      );
    }
  };

  const handleToggleDropdown = (dropdown) => {
    setActiveDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  useEffect(() => {
    const tabFromUrl = searchParams.get("tab");
    if (tabFromUrl && tabFromUrl.includes("connections")) {
      setActiveTab("Connections");
    }
  }, [searchParams]);

  useEffect(() => {
    if (newConversationData) {
      sendBrevoEmail(selectedConnection.id);
      closePopup();
    }
  }, [newConversationData]);

  useEffect(() => {
    if (currentUser) {
      const {
        name,
        bio,
        lookingFor,
        whatIBring,
        location,
        email,
        phone,
        birthday,
        skills,
        experience,
        tools,
        hoursAvailable,
        photoUrl,
        notificationSettings,
        connections,
      } = currentUser;

      setName(name || "");
      // const [first, ...last] = (name || "").split(" ");
      // setFirstName(first || "");
      // setLastName(last.join(" ") || "");
      setBio(bio || "");
      setLookingFor(lookingFor || "");
      setWhatIBring(whatIBring || "");
      setLocation(location || "");
      setEmail(email || "");
      setPhone(phone || "");
      setBirthday(birthday || "");
      setSkills(skills);
      setSelectedSkills(skills?.split(",") || []);
      setTools(tools);
      setSelectedTools(tools?.split(",") || []);
      setExperience(experience);
      setSelectedExperience(experience?.split(",") || []);
      setHoursAvailable(hoursAvailable);
      setHoursAvailable(hoursAvailable || "");
      setPhotoUrl(photoUrl || null);
      setNotificationSettings(
        notificationSettings || {
          emailNotifications: false,
          pushNotifications: false,
          messageNotifications: false,
          newUserNotifications: false,
        }
      );
      setUserConnections(connections || "");

      if (location) {
        const parts = location.split(",");
        setCity(parts[0] ? parts[0].trim() : "");
        setStateProvince(parts[1] ? parts[1].trim() : "");
        setCountry(parts[2] ? parts[2].trim() : "");
      } else {
        setCity("");
        setStateProvince("");
        setCountry("");
      }
    }
  }, [currentUser]);

  const handleTabClick = (tab) => setActiveTab(tab);

  const openConnectionPopup = (connection) => {
    setSelectedConnection(connection);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedConnection(null);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const toggleAcctEditMode = () => {
    setAcctEditMode(!acctEditMode);
  };

  useEffect(() => {
    if (isUpdateProfileSuccess) {
      alert("Profile saved successfully.");
      setEditMode(false);
      setAcctEditMode(false);
      setSaveError("");
    }
  }, [isUpdateProfileSuccess]);

  const saveProfileToDynamoDB = async (fileUrl) => {
      if (!currentUser) {
        console.error("User is not authenticated");
        setSaveError("Unable to save: User not authenticated.");
        return;
      }

    const combinedLocation = [city, stateProvince, country]
      .filter(Boolean)
      .join(", ");
    const cleanedData = {
      name: name,
      location: combinedLocation,
      skills: selectedSkills.length ? selectedSkills.join(",") : undefined,
      tools: selectedTools.length ? selectedTools.join(",") : undefined,
      hoursAvailable: hoursAvailable,
      // photoUrl: fileUrl || photoUrl,
      bio: bio,
      lookingFor: lookingFor,
      whatIBring: whatIBring,
      email: email,
      phone: phone,
      birthday: birthday,
      experience: selectedExperience.length
        ? selectedExperience.join(",")
        : undefined,
      notificationSettings: notificationSettings,
    };

    updateProfile(cleanedData);

    // const dynamodb = new AWS.DynamoDB.DocumentClient();
    // const params = {
    //   TableName: config.aws.usersTableName,
    //   Item: cleanedData,
    // };

    // try {
    //   await dynamodb.put(params).promise();
    //   alert("Profile saved successfully.");
    //   setEditMode(false);
    //   setAcctEditMode(false);
    //   setSaveError("");
    //   window.location.reload();
    // } catch (error) {
    //   console.error("Error saving profile data to DynamoDB:", error);
    //   setSaveError("Failed to save profile. Please try again.");
    // }
  };

  useEffect(() => {
    if (file) {
      uploadFile();
    }
  }, [file]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const localPreviewUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(localPreviewUrl);
    }
  };

  const uploadFile = async () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    setIsUploading(true);

    const S3_BUCKET = config.aws.s3.bucketName;
    const REGION = config.aws.s3.region;

    AWS.config.update({
      region: config.aws.region,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.aws.identityPoolId,
      }),
    });

    const s3 = new AWS.S3({ params: { Bucket: S3_BUCKET }, region: REGION });
    const params = {
      Bucket: S3_BUCKET,
      Key: file.name,
      Body: file,
    };

    try {
      const data = await s3.upload(params).promise();

      const fileUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`;

      setPhotoUrl(fileUrl);
      setUpdatedPhotoUrl(fileUrl);
      setFile(null);
      // saveProfileToDynamoDB(fileUrl);
      updateProfile({ photoUrl: fileUrl });
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteAccount = () => {
    alert(
      "Account deletion functionality is not implemented yet. Please send an email to hello@pressplay.gg and we will process your request."
    );
  };

  useEffect(() => {
    if (startConversationError) {
      toast(startConversationError.data?.error, {
        position: "top-left",
        className: "toast-message",
      });
    }
  }, [startConversationError]);

  useEffect(() => {
    if (isRemoveConnectionSuccess) {
      alert(
        `${selectedConnection.name} has been removed from your connections.`
      );
      closePopup();
    }
  }, [isRemoveConnectionSuccess]);

  return (
    <div
      className="edit-profile"
      style={{
        background: "white",
        minHeight: "100vh",
        paddingLeft: "100px",
        paddingRight: "100px",
        paddingTop: "300px",
        marginTop: "-20vh",
      }}
    >
      {saveError && (
        <div className="error-message">
          <p>{saveError}</p>
        </div>
      )}
      <div className="profile-picture-container">
            <img
              src={previewUrl || photoUrl || DefaultUserImage}
              alt="User"
              className="profile-picture"
            />
        <span
          className="pencil-icon"
          onClick={() => document.getElementById("fileInput").click()}
        >
          <FiEdit />
        </span>
        <div className="flex items-center gap-1">
          {(!photoUrl || photoUrl.length === 0) && <IncompleteSectionBadge />}
        </div>

        <input
          type="file"
          id="fileInput"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
      <div className="upload-container">
        {isUploading ? (
          <Spinner className="spinner" />
        ) : (
          file && (
            <button onClick={uploadFile} className="upload-button">
              {/* Upload Photo */}
            </button>
          )
        )}
      </div>
      <div className="tab-menu">
        {["Profile", "Connections", "Notifications", "Account"].map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabClick(tab)}
            className={activeTab === tab ? "tab-button active" : "tab-button"}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {activeTab === "Profile" && (
          <div className="details-section flex flex-col gap-3">
            <ProfileCompletionBanner isComplete={isProfileComplete} />
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <label>Name</label>
                {name.length === 0 && <IncompleteSectionBadge />}
              </div>

              {editMode ? (
                <input
                  type="text"
                  value={name}
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              ) : (
                <span className={!bio ? "placeholder-text" : ""}>
                  {name || "Name"}
                </span>
              )}
            </div>
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <label>Headline</label>
                {bio.length === 0 && <IncompleteSectionBadge />}
              </div>
              {editMode ? (
                <input
                  type="text"
                  placeholder="Short description about yourself..."
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                />
              ) : (
                <span className={!bio ? "placeholder-text" : ""}>
                  {bio || "Add a short description about yourself..."}
                </span>
              )}
            </div>

            {/* Skills Section */}
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <label>My Skills</label>
                {selectedSkills.length === 0 && <IncompleteSectionBadge />}
              </div>
              {editMode ? (
                <>
                  <FilterDropdown
                    options={[
                      "Development",
                      "Design",
                      "Project Management",
                      "QA Testing",
                      "Mobile",
                      "Narrative",
                      "Sound Design",
                      "Soundtrack & Scoring",
                      "AI",
                      "VR/AR",
                      "2D Art",
                      "3D Art",
                      "Level Design",
                      "Character Design",
                      "Animation",
                    ]}
                    onSelect={(option) => handleAddSelection("Skills", option)}
                    isOpen={activeDropdown === "skills"}
                    onToggle={() => handleToggleDropdown("skills")}
                    filterOption={"Skills"}
                  />
                  <div className="bubbles-container">
                    {selectedSkills.map((skill, index) => (
                      <div key={index} className="bubble category-skills">
                        {skill}
                        <button
                          className="close-btn"
                          onClick={() => handleRemoveSelection("Skills", skill)}
                        >
                          ✕
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <span
                  className={!selectedSkills.length ? "placeholder-text" : ""}
                >
                  {selectedSkills.length > 0
                    ? selectedSkills.join(", ")
                    : "No skills added yet"}
                </span>
              )}
            </div>

             {/* Experience Section */}
             <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <label>Experience</label>
                {selectedExperience.length === 0 && <IncompleteSectionBadge />}
              </div>
              {editMode ? (
                <>
                  <FilterDropdown
                    options={[
                      "Hobbyist",
                      "Indie",
                      "AA",
                      "AAA",
                      "OG",
                      "Award Winner",
                      "Game Jams",
                      "Shipped Games",
                    ]}
                    onSelect={(option) =>
                      handleAddSelection("Experience", option)
                    }
                    isOpen={activeDropdown === "experience"}
                    onToggle={() => handleToggleDropdown("experience")}
                    filterOption={"Experience"}
                  />
                  <div className="bubbles-container">
                    {selectedExperience.map((experience, index) => (
                      <div key={index} className="bubble category-tools">
                        {experience}
                        <button
                          className="close-btn"
                          onClick={() =>
                            handleRemoveSelection("Experience", experience)
                          }
                        >
                          ✕
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <span
                  className={!selectedTools.length ? "placeholder-text" : ""}
                >
                  {selectedExperience.length > 0
                    ? selectedExperience.join(", ")
                    : "No experience added yet"}
                </span>
              )}
            </div>

            {/* Tools Section */}
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <label>Tools</label>
              </div>
              {editMode ? (
                <>
                  <FilterDropdown
                    options={[
                      "Unreal Engine",
                      "Unity",
                      "Godot",
                      "CryEngine",
                      "RPG Maker",
                      "GameMaker Studio",
                      "Blender",
                      "Maya",
                      "3ds Max",
                      "ZBrush",

                    ]}
                    onSelect={(option) => handleAddSelection("Tools", option)}
                    isOpen={activeDropdown === "tools"}
                    onToggle={() => handleToggleDropdown("tools")}
                    filterOption={"Tools"}
                  />
                  <div className="bubbles-container">
                    {selectedTools.map((tool, index) => (
                      <div key={index} className="bubble category-tools">
                        {tool}
                        <button
                          className="close-btn"
                          onClick={() => handleRemoveSelection("Tools", tool)}
                        >
                          ✕
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <span
                  className={!selectedTools.length ? "placeholder-text" : ""}
                >
                  {selectedTools.length > 0
                    ? selectedTools.join(", ")
                    : "No tools added yet"}
                </span>
              )}
            </div>

           

            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <label>What I make</label>
              </div>
              {editMode ? (
                <textarea
                  onChange={(e) => setWhatIBring(e.target.value)}
                  value={whatIBring}
                  placeholder="Tell 'em what you enjoy making..."
                  rows="4"
                />
              ) : (
                <span className={!whatIBring ? "placeholder-text" : ""}>
                  {whatIBring || "Tell 'em what you enjoy making..."}
                </span>
              )}
            </div>
            <label>City</label>
            <div>
              {editMode ? (
                <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              ) : (
                <span>{city}</span>
              )}
            </div>

            <label>State/Province</label>
            <div>
              {editMode ? (
                <input
                  type="text"
                  value={stateProvince}
                  onChange={(e) => setStateProvince(e.target.value)}
                />
              ) : (
                <span>{stateProvince}</span>
              )}
            </div>

            <label>Country</label>
            <div>
              {editMode ? (
                <input
                  type="text"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              ) : (
                <span>{country}</span>
              )}
            </div>
            {/* 
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <label>Hours Available</label>
                {hoursAvailable.length === 0 && <IncompleteSectionBadge />}
              </div>
              {editMode ? (
                <select
                  value={hoursAvailable}
                  onChange={(e) => setHoursAvailable(e.target.value)}
                >
                  <option value="0-5">0-5</option>
                  <option value="5-15">5-15</option>
                  <option value="20-25">20-25</option>
                  <option value="40+">40+</option>
                </select>
              ) : (
                <span className={!hoursAvailable ? "placeholder-text" : ""}>
                  {hoursAvailable || "Select your availability..."}
                </span>
              )}
            </div> */}

            <div className="button-group">
              <button
                className={`edit-toggle-button ${
                  editMode ? "cancel-mode" : "cancel-mode"
                }`}
                onClick={toggleEditMode}
                disabled={isUpdateProfileLoading}
              >
                {editMode ? "Cancel Edit" : "Edit"}
              </button>
              {editMode && (
                <button
                  className="save-button"
                  onClick={() => saveProfileToDynamoDB(photoUrl)}
                  disabled={isUpdateProfileLoading}
                >
                  Save Profile
                </button>
              )}
            </div>
          </div>
        )}
        {activeTab === "Connections" && (
          <div className="connections-section">
            {!connectionsData || connectionsData.connections.length === 0 ? (
              <p className="no-connections-text">
                You haven't made any connections yet.
              </p>
            ) : (
              connectionsData.connections.map((connection) => (
                <img
                  key={connection.id}
                  src={connection.photoUrl}
                  alt={connection.name}
                  className="connection-icon"
                  onClick={() => openConnectionPopup(connection)}
                />
              ))
            )}
          </div>
        )}
        {activeTab === "Notifications" && (
          <div className="notifications-section">
            <h3>Notification Preferences</h3>

            <div className="toggle-container">
              <label className="toggle-label">
                <div
                  className={`toggle-switch ${
                    notificationSettings.emailNotifications ? "active" : ""
                  }`}
                  onClick={() =>
                    setNotificationSettings((prev) => ({
                      ...prev,
                      emailNotifications: !prev.emailNotifications,
                    }))
                  }
                >
                  <div className="toggle-knob"></div>
                </div>
                <span>Email Notifications</span>
              </label>

              <label className="toggle-label">
                <div
                  className={`toggle-switch ${
                    notificationSettings.pushNotifications ? "active" : ""
                  }`}
                  onClick={() =>
                    setNotificationSettings((prev) => ({
                      ...prev,
                      pushNotifications: !prev.pushNotifications,
                    }))
                  }
                >
                  <div className="toggle-knob"></div>
                </div>
                <span>Push Notifications</span>
              </label>

              <label className="toggle-label">
                <div
                  className={`toggle-switch ${
                    notificationSettings.messageNotifications ? "active" : ""
                  }`}
                  onClick={() =>
                    setNotificationSettings((prev) => ({
                      ...prev,
                      messageNotifications: !prev.messageNotifications,
                    }))
                  }
                >
                  <div className="toggle-knob"></div>
                </div>
                <span>Notify me when I get a message</span>
              </label>

              <label className="toggle-label">
                <div
                  className={`toggle-switch ${
                    notificationSettings.newUserNotifications ? "active" : ""
                  }`}
                  onClick={() =>
                    setNotificationSettings((prev) => ({
                      ...prev,
                      newUserNotifications: !prev.newUserNotifications,
                    }))
                  }
                >
                  <div className="toggle-knob"></div>
                </div>
                <span>
                  Notify me when new users join the site when they could be a
                  great fit for me
                </span>
              </label>
            </div>
            <div className="button-group">
              <button
                className="save-button"
                onClick={() => saveProfileToDynamoDB(notificationSettings)}
              >
                Save
              </button>
            </div>
          </div>
        )}
        {/* {activeTab === "Billing" && (
            <div className="billing-section">
              <h3>Billing Details</h3>
              <p>Manage your subscription and payment details.</p>
              <button
                className="billing-portal-button"
                onClick={() => {
                  window.location.href = config.stripePaymentUrl;
                }}
              >
                Manage Billing
              </button>
            </div>
          )} */}
        {activeTab === "Account" && (
          <div className="details-section">
            <label>Email</label>
            <div>
              {acctEditMode ? (
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              ) : (
                <span>{email}</span>
              )}
            </div>
            <label>Phone</label>
            <div>
              {acctEditMode ? (
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              ) : (
                <span>{phone}</span>
              )}
            </div>
            <label>Birthday</label>
            <div>
              {acctEditMode ? (
                <input
                  type="date"
                  value={birthday}
                  onChange={(e) => setBirthday(e.target.value)}
                />
              ) : (
                <span>{birthday}</span>
              )}
            </div>
            <div className="button-group">
              <button
                className={`edit-toggle-button ${
                  acctEditMode ? "cancel-mode" : "edit-mode"
                }`}
                onClick={toggleAcctEditMode}
              >
                {acctEditMode ? "Cancel Edit" : "Edit"}
              </button>
              {acctEditMode && (
                <button
                  className="save-button"
                  onClick={() => saveProfileToDynamoDB(photoUrl)}
                >
                  Save
                </button>
              )}
            </div>
            <div className="delete-account-section">
              <p>
                Once you delete your account, all data will be removed
                permanently.
              </p>
              <button className="delete-button" onClick={handleDeleteAccount}>
                Delete Account
              </button>
            </div>
          </div>
        )}
      </div>

      {showPopup && selectedConnection && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-card" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closePopup}>
              X
            </button>

            {/* Profile Picture */}
            <div className="profile-picture-container">
              <img
                src={selectedConnection.photoUrl}
                alt={selectedConnection.name}
                className="profile-picture"
              />
            </div>

            {/* Basic Info */}
            {selectedConnection.name && (
              <h3 className="popup-card__name">{selectedConnection.name}</h3>
            )}

            {selectedConnection.location && (
              <p>
                <strong>Location:</strong> {selectedConnection.location}
              </p>
            )}

            {/* Skills & Tools Lists */}
            {selectedConnection.skills &&
              selectedConnection.skills.length > 0 && (
                <p>
                  <strong>Skills:</strong>{" "}
                  {selectedConnection.skills.join(", ")}
                </p>
              )}

            {selectedConnection.tools &&
              selectedConnection.tools.length > 0 && (
                <p>
                  <strong>Tools:</strong> {selectedConnection.tools.join(", ")}
                </p>
              )}

            {/* Other Profile Details */}
            {selectedConnection.goals && (
              <p>
                <strong>Goals:</strong> {selectedConnection.goals}
              </p>
            )}

            {selectedConnection.lookingFor && (
              <p>
                <strong>Who I want to work with:</strong>{" "}
                {selectedConnection.lookingFor}
              </p>
            )}

            {selectedConnection.whatIBring && (
              <p>
                <strong>What I bring:</strong> {selectedConnection.whatIBring}
              </p>
            )}

            {selectedConnection.experience && (
              <p>
                <strong>Experience:</strong> {selectedConnection.experience}
              </p>
            )}

            {selectedConnection.hoursAvailable && (
              <p>
                <strong>Time Availability:</strong>{" "}
                {selectedConnection.hoursAvailable}
              </p>
            )}

            {/* Action Buttons */}
            <button
              className="remove-button"
              onClick={() => {
                removeConnection(selectedConnection.id);
              }}
              disabled={isRemoveConnectionLoading}
            >
              Remove from Connections
            </button>
            <button
              className="message-button"
              onClick={() => {
                if (!selectedConnection) {
                  alert("No connection selected");
                  return;
                }
                startConversation({
                  recipientId: selectedConnection.id,
                  initialMessage: `Hi ${selectedConnection.name}, let's connect!`,
                });
              }}
              disabled={isStartConversationLoading}
            >
              Message
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditProfile;
