import React, { Component } from 'react';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import {
  authenticateUser,
  initiatePasswordReset,
  confirmPasswordReset,
  getCurrentUser,
  verifyUser,
} from '../Cognito'; 
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import config from '../config';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Signin.css';
import { BASE_PATH } from '../utils/constants';

const userPool = new CognitoUserPool({
  UserPoolId: config.cognito.userPoolId,
  ClientId: config.cognito.clientId,
});

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      verifyCode: '',
      newPassword: '',
      username: '',
      loading: false,
      loginError: '',
      redirect: false, 
      showVerification: false,
      resetStep: 1,
      showForgotPassword: false, 
    };
  }


  async componentDidMount() {
    const currentUser = userPool.getCurrentUser(); 
  console.log(currentUser);
    if (currentUser) {
      currentUser.getSession((err, session) => {
        if (err || !session.isValid()) {
          console.log("Session expired. Logging out.");
          this.setState({ redirect: false });
          currentUser.signOut(); 
          return;
        }
  
        console.log("Session is valid.");
        this.setState({ redirect: true });
      });
    }
  }
  
  

  changeEmail = (e) => this.setState({ email: e.target.value });
  changePassword = (e) => this.setState({ password: e.target.value });
  changeNewPassword = (e) => this.setState({ newPassword: e.target.value });
  changeVerifyCode = (e) => this.setState({ verifyCode: e.target.value });

  handleSigninSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true, loginError: '' });

    authenticateUser(this.state.email, this.state.password, (err, result) => {
      if (err) {
        if (err.code === "UserNotConfirmedException") {
          this.setState({
            loading: false,
            showVerification: true,
            username: this.state.email,
            loginError: "Your email is not verified. Please verify to continue.",
          });
        } else {
          this.setState({
            loading: false,
            loginError: "Invalid email or password. Please try again.",
          });
        }
        return;
      }
      window.location.reload();
    });
  };

  handleVerifySubmit = async (e) => {
    e.preventDefault();
    const { username, verifyCode, email, password, signupCode } = this.state;
  
    verifyUser(username, verifyCode, async (err, result) => {
      if (err) {
        toast.error(err.message || "Verification failed. Please try again.");
        return;
      }
      toast.success("Verification successful! Logging you in...");

      authenticateUser(email, password, async (err, loginResult) => {
        if (err) {
          toast.error(err.message || "Login failed. Please try to log in manually.");
          return;
        }

        const idToken = loginResult.getIdToken().getJwtToken();
        const decodedToken = JSON.parse(
          atob(idToken.split('.')[1])
        );
        const cognitoId = decodedToken.sub;

        AWS.config.update({
          region: config.aws.region,
          credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.aws.identityPoolId,
          }),
        });

        try {
          const dynamodb = new AWS.DynamoDB.DocumentClient();
          const params = {
            TableName: config.aws.usersTableName,
            Item: {
              cognitoId: cognitoId,
              email: email,
              referralCode: signupCode,
            },
          };

          await dynamodb.put(params).promise();
          window.location.reload(); 
        } catch (dbError) {
          console.error("Error saving email to Users table:", dbError);
          toast.error("Failed to signup user, please try again.");
        }
      });
    });
  };
  

  resendVerificationCode = () => {
    const { username } = this.state;
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        toast.error(err.message || 'Could not resend code. Please try again.');
        return;
      }
      toast.success('Verification code resent successfully. Please check your email and check spam folder.');
    });
  };
  

  handleForgotPasswordSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;

    this.setState({ loading: true });

    initiatePasswordReset(email, (err) => {
      if (err) {
        toast.error(err.message || "Failed to send reset code.");
        this.setState({ loading: false });
        return;
      }

      toast.success("Verification code sent. Check your email.");
      this.setState({ resetStep: 2, loading: false });
    });
  };

  // Handle password reset confirmation
  handleConfirmPasswordSubmit = (e) => {
    e.preventDefault();
    const { email, verifyCode, newPassword } = this.state;

    this.setState({ loading: true });

    confirmPasswordReset(email, verifyCode, newPassword, (err) => {
      if (err) {
        toast.error(err.message || "Failed to reset password.");
        this.setState({ loading: false });
        return;
      }

      toast.success("Password reset successful! Redirecting...");
      setTimeout(() => window.location.reload(), 2000); 
    });
  };

  toggleForgotPassword = () => {
    this.setState({
      showForgotPassword: !this.state.showForgotPassword,
      loginError: '',
    });
  };

  render() {
    const {
      email,
      password,
      verifyCode,
      newPassword,
      loading,
      loginError,
      redirect,
      showForgotPassword,
      resetStep,
      showVerification,
    } = this.state;
  
    if (redirect) {
      return <Navigate to={`${BASE_PATH}/edit-profile`} />;
    }

    return (
      <div className="container-12">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
        <div className="signin-container">
          <h2 className="signin-header">
            {showForgotPassword ? "Reset Password" : showVerification ? "Verify Your Account" : "Sign In"}
          </h2>
    
          {showForgotPassword ? (
            <>
              {resetStep === 1 ? (
                <form onSubmit={this.handleForgotPasswordSubmit} className="forgot-password-form">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      value={email}
                      className="form-input"
                      placeholder="Enter your email"
                      onChange={this.changeEmail}
                      required
                    />
                  </div>
                  <button type="submit" className="submit-button" disabled={loading}>
                    {loading ? "Sending Reset Code..." : "Send Reset Code"}
                  </button>
                </form>
              ) : (
                <form onSubmit={this.handleConfirmPasswordSubmit} className="forgot-password-form">
                  <div className="form-group">
                    <label>Verification Code</label>
                    <input
                      type="text"
                      value={verifyCode}
                      className="form-input"
                      placeholder="Enter verification code"
                      onChange={this.changeVerifyCode}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>New Password</label>
                    <input
                      type="password"
                      value={newPassword}
                      className="form-input"
                      placeholder="Enter new password"
                      minLength={6}
                      onChange={this.changeNewPassword}
                      required
                    />
                  </div>
                  <button type="submit" className="submit-button" disabled={loading}>
                    {loading ? "Resetting Password..." : "Reset Password"}
                  </button>
                </form>
              )}
              <button
                type="button"
                className="back-button secondary-button"
                onClick={this.toggleForgotPassword}
              >
                Back to Sign In
              </button>
            </>
          ) : showVerification ? (
            // Verification UI - Cloning the Signup Verification Process
            <form onSubmit={this.handleVerifySubmit} className="verify-form">
              <div className="form-group">
                <input
                  className="form-input"
                  value={verifyCode}
                  onChange={this.changeVerifyCode}
                  placeholder="Enter verification code"
                  required
                />
              </div>
              <div className="form-group">
                <button type="submit" className="submit-button">Verify</button>
                <button
                  type="button"
                  onClick={this.resendVerificationCode}
                  className="resend-button"
                >
                  Resend Code
                </button>
              </div>
            </form>
          ) : (
            // Standard Sign-In Form
            <form onSubmit={this.handleSigninSubmit} className="signin-form">
              <div className="form-group">
                <input
                  type="email"
                  value={email}
                  className="form-input"
                  placeholder="Email"
                  onChange={this.changeEmail}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  value={password}
                  className="form-input"
                  placeholder="Password"
                  minLength={6}
                  onChange={this.changePassword}
                  required
                />
              </div>
    
              {loginError && <div className="error-message">{loginError}</div>}
    
              <div className="form-group">
                <button
                  type="submit"
                  className="submit-button primary-button"
                  disabled={loading}
                >
                  {loading ? "Signing In..." : "Sign In"}
                </button>
              </div>
    
              <p className="forgot-password-text">
                <button
                  type="button"
                  className="forgot-password-link secondary-button"
                  onClick={this.toggleForgotPassword}
                >
                  Forgot Password?
                </button>
              </p>
            </form>
          )}
        </div>
      </div>
    );
    
  }
}

export default Signin;
