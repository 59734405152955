import { useMemo } from "react";
import config from "../config";
import useWebSocket from "react-use-websocket";
import { userPool } from "../Cognito";

export const useChatSocket = (userId, onMessage) => {
  const cognitoUser = userPool.getCurrentUser();
  const cognitoToken = useMemo(() => {
    let cognitoCred = null;
    if (cognitoUser) {
      cognitoUser.getSession((_, session) => {
        cognitoCred = session.idToken.jwtToken;
        if (!cognitoCred) {
          console.error("No token found!!");
        }
      });
    } else {
      console.error("No user session!!");
    }
    return cognitoCred;
  }, [cognitoUser]);
  const socketUrl = useMemo(
    () => `${config.websocketUrl}?cognitoId=${userId}&token=${cognitoToken}`,
    [userId, cognitoToken]
  );
  const { sendMessage, readyState } = useWebSocket(
    socketUrl,
    {
      shouldReconnect: (event) => userId != null && cognitoToken != null,
      share: true,
      onMessage,
    },
    userId != null && cognitoToken != null
  );

  return {
    sendMessage: (message) => {
      if (readyState !== WebSocket.OPEN) {
        alert("Cannot send message. Service not connected.");
        return false;
      }
      sendMessage(JSON.stringify(message));
      return true;
    },
    readyState,
  };
};
