import React, { useEffect, useMemo, useState } from "react";
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { createUser, verifyUser, authenticateUser } from "../Cognito";
import { ToastContainer, toast } from "react-toastify";
import screenshot0 from "../imgs/editprofile6-screenshot.png";
import screenshot1 from "../imgs/findateam-screenshot.png";
import screenshot2 from "../imgs/messaging-screenshot.png";
import meme1 from "../imgs/meme1.png";
import { Navigate } from "react-router-dom";
import config from "../config";
import "react-toastify/dist/ReactToastify.css";
import "../styles/Signup.css";
import { BASE_PATH } from "../utils/constants";
import { useSignUpMutation } from "../utils/APIUtil";

const userPool = new CognitoUserPool({
  UserPoolId: config.cognito.userPoolId,
  ClientId: config.cognito.clientId,
});

//FB: J2BWA9
//Reddit: BOGFCP
//LinkedIn: 2YOAAN
//Discord/BlueSky: 3ZOZ9A
const validSignupCodes = ["J2BWA9", "BOGFCP", "2YOAAN", "3ZOZ9A"];

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [signupCode, setSignupCode] = useState("");
  const [modalImage, setModalImage] = useState(null);
  const [invalidSignupCode, setInvalidSignupCode] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const passwordMatch = useMemo(
    () => password === confirmPassword,
    [password, confirmPassword]
  );

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const cognitoUser = userPool.getCurrentUser();
  const [signUpServer, { isSuccess: signupSuccess, isLoading: signupLoading }] =
    useSignUpMutation();

  const handleSignupSubmit = (e) => {
    e.preventDefault();

    if (!validSignupCodes.includes(signupCode)) {
      setInvalidSignupCode(true);
      toast.error("Invalid signup code. Please try again.");
      return;
    }
    setInvalidSignupCode(false);

    if (!passwordMatch) {
      toast.error("Passwords do not match");
      return;
    }

    createUser(email, email, password, (err, result) => {
      if (err) {
        toast.error(err.message || "An error occurred during signup");
        return;
      }

      // don't check response for now
      signUpServer({
        cognitoId: result.userSub,
        email,
        referralCode: signupCode,
      });

      toast.success(
        "Sign up successful! Please check your email for the verification code."
      );
    });
  };

  useEffect(() => {
    if (signupSuccess) {
      setShowVerification(true);
    }
  }, [signupSuccess]);

  const handleVerifySubmit = (e) => {
    e.preventDefault();
    verifyUser(email, verifyCode, async (err, result) => {
      if (err) {
        toast.error(err.message || "Verification failed. Please try again.");
        return;
      }
      toast.success("Verification successful! Logging you in...");

      authenticateUser(email, password, async (err, loginResult) => {
        if (err) {
          toast.error(
            err.message || "Login failed. Please try to log in manually."
          );
          return;
        }
        window.location.reload();
      });
    });
  };



  const resendVerificationCode = () => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        toast.error(err.message || "Could not resend code. Please try again.");
        return;
      }
      toast.success(
        "Verification code resent successfully. Please check your email."
      );
    });
  };

  if (cognitoUser) {
    return <Navigate to={`${BASE_PATH}/edit-profile`} />;
  }

  const handleEmailRequest = () => {
    const email = "hello@pressplay.gg";
    const subject = "Beta Access PressPlay Signup Code Request";
    const body = "I'm interested in joining the PressPlay!";
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
    window.location.href = mailtoLink;
  };

  return (
    <div className="container-14">
      <div className="scroll-container">
      <section className="home__hero-waitlist">
            <div id="container15" className="home__hero-bg">
              <p
                style={{
                  marginBottom: "0",
                  color: "#606060",
                  fontSize: "1.75rem",
                  fontWeight: "800",
                  fontVariant: "all-small-caps",
                }}
                className="home__hero-waitlist_R1R1"
              >
                Join the Indie Game Creator Community
              </p>
              <p
                style={{
                  margin: "0",
                  fontSize: "3.5rem",
                  fontWeight: "800",
                  color: "#393939",
                  fontVariant: "all-small-caps",
                }}
                className="home__hero-waitlist_R1R2"
              >
                Team Up - Make Games
              </p>
              <p
                style={{
                  margin: "0",
                  fontSize: "1.75rem",
                  fontWeight: "800",
                  color: "#606060",
                  fontVariant: "all-small-caps",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
                className="home__hero-waitlist_R1R3"
              >
                Matchmaking for game devs & artists
              </p>
              <p
                style={{
                  color: "#6B62FF",
                  fontSize: "1.5rem",
                  fontWeight: "800",
                  fontVariant: "all-small-caps",
                  paddingBottom: "10px",
                  paddingTop: "0px",

                }}
                className="home__hero-waitlist_R1R5"
              >
              It's dangerous to go alone
              </p>
              
            </div>
          </section>
          {/* <div className="scroll-arrow">
              <div className="arrow"></div>
            </div> */}
      <div className="signup-container">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar />

        <h2 className="signup-header">Sign Up</h2>

        {!showVerification ? (
          <form onSubmit={handleSignupSubmit} className="signup-form">
            <div className="form-group">
            <input
                className="form-input"
                value={signupCode}
                placeholder="Signup Code"
                type="text"
                onChange={(e) => setSignupCode(e.target.value)}
                required
                title="Enter a valid signup code. If you don't have one, email hello@pressplay.gg to request a code."
              />
            </div>
            {invalidSignupCode && (
              <p className="error-message">Invalid signup code</p>
            )}
            <div className="form-group">
              <input
                className="form-input"
                value={email}
                placeholder="Email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <input
                className="form-input"
                value={password}
                placeholder="Password"
                type="password"
                minLength={6}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <input
                className="form-input"
                value={confirmPassword}
                placeholder="Confirm Password"
                type="password"
                minLength={6}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {!passwordMatch && (
              <p className="error-message">Passwords do not match</p>
            )}
            <div className="form-group">
              <button
                type="submit"
                className="submit-button"
                disabled={!passwordMatch || signupLoading}
              >
                Sign Up
              </button>
            </div>
            <div className="form-group">
              <p className="signin-text">
                Already have an account?{" "}
                <a href={`${BASE_PATH}/login`} className="signin-link">
                  Sign In
                </a>
              </p>
            </div>
          </form>
        ) : (
          <form onSubmit={handleVerifySubmit} className="verify-form">
            <div className="form-group">
              <input
                className="form-input"
                value={verifyCode}
                onChange={(e) => setVerifyCode(e.target.value)}
                placeholder="Verification Code"
                required
              />
            </div>
            <div className="form-group">
              <button type="submit" className="submit-button">
                Verify
              </button>
              <button
                type="button"
                onClick={resendVerificationCode}
                className="resend-button"
              >
                Resend Code
              </button>
            </div>
          </form>
        )}
      </div>
      
  <div className="scroll-arrow">
    <div className="arrow"></div>
  </div>
</div>
       {/* How It Works Section */}
       <div
          style={{
            backgroundColor: "#6B62FF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="container-16"
        >
          <div className="additional-content">
            <div className={isMobile ? "team-up-mobile" : "team-up"}>
              <div className="team-up-text">How It Works</div>
            </div>
            <div
              className={isMobile ? "image-row-mobile" : "image-row"}
            >
              <div className="how-it-works-step">
                <img
                  src={screenshot0}
                  alt="Create Your Profile"
                  className="side-by-side-image"
                  onClick={() => setModalImage(screenshot0)}
                />
                <h3>CREATE YOUR PROFILE</h3>
                <p
                  style={{
                    marginLeft: "60px",
                    marginRight: "60px",
                    fontWeight: "300",
                  }}
                >
                  Share your skills, passions and project ideas.
                </p>
              </div>
              <div className="how-it-works-step">
                <img
                  src={screenshot1}
                  alt="Find Your Team"
                  className="side-by-side-image"
                  onClick={() => setModalImage(screenshot1)}
                />
                <h3>FIND YOUR TEAM</h3>
                <p
                  style={{
                    marginLeft: "60px",
                    marginRight: "60px",
                    fontWeight: "300",
                  }}
                >
                  Use filters to connect with developers, designers, and
                  artists.
                </p>
              </div>
              <div className="how-it-works-step">
                <img
                  src={screenshot2}
                  alt="Build Together"
                  className="side-by-side-image"
                  onClick={() => setModalImage(screenshot2)}
                />
                <h3>BUILD TOGETHER</h3>
                <p
                  style={{
                    marginLeft: "60px",
                    marginRight: "60px",
                    fontWeight: "300",
                  }}
                >
                  Collaborate on projects and bring your game to life.
                </p>
              </div>
            </div>
          </div>
        </div>

        {modalImage && (
          <div
            className="image-modal"
            onClick={() => setModalImage(null)}
          >
            <img
              src={modalImage}
              alt="Enlarged view"
              className="modal-image"
            />
          </div>
        )}

        {/* Spotlight Section */}
        <div className="spotlight-section">
          <h2>A Supportive Community</h2>
          <div className="spotlight-content">
            <img
              src={meme1}
              alt="meme of indie developer"
              className="side-by-side-image"
              onClick={() => setModalImage(meme1)}
            />
            <p style={{ fontWeight: "300" }}>
              PressPlay is a community of creators who love to make games. We're
              here to help you find your people, get inspired, and build
              something amazing. Discover incredible indie games, talented
              artists, and passionate creators. We're dedicated to showcasing
              the amazing work coming out of our community and helping you make
              meaningful connections.
            </p>
          </div>
        </div>
       <div className="alpha-access">
          <h2>Need A Signup Code?</h2>
          <p>
            To request a signup code please email hello@pressplay.gg with the subject line "PressPlay Signup Code Request" or use the button below.
          </p>
          <button
            className="alpha-access-button"
             onClick={handleEmailRequest}
          >
            Request Access!
          </button>
        </div>
    </div>
  );
};

export default Signup;
