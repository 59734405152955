import React, { useState, useEffect, useCallback } from "react";
import ConversationList from "./ConversationList";
import ActiveConversation from "./ActiveConversation";
import "../styles/Sidebar.css";
import Spinner from "./Spinner";
import { useCurrentUser } from "../utils/APIHooks";
import api, { useGetConversationsQuery } from "../utils/APIUtil";
import { useChatSocket } from "../utils/chatSocketUtil";
import { useDispatch } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

const Sidebar = ({ onToggle }) => {
  const [selectedConversation, setSelectedConversation] = useState(null);
  const currentUser = useCurrentUser();
  const { data, isLoading, error } = useGetConversationsQuery(undefined, {
    skip: currentUser == null,
  });
  const dispatch = useDispatch();
  useChatSocket(
    currentUser?.cognitoId,
    useCallback(
      (event) => {
        const response = JSON.parse(event.data);
        const { action } = response;

        switch (action) {
          case "conversation_update": {
            dispatch(api.util.invalidateTags(["conversations"]));
            break;
          }
          case "chat_update": {
            // might have new notification
            dispatch(api.util.invalidateTags(["conversations"]));
            break;
          }
          default:
            break;
        }
      },
      [selectedConversation]
    )
  );

  const handleSelectConversation = (conversation) => {
    if (selectedConversation?.convoId === conversation.convoId) {
      setSelectedConversation(null);
      onToggle(false);
    } else {
      setSelectedConversation(conversation);
      onToggle(true);
    }
  };

  const handleCloseConversation = () => {
    setSelectedConversation(null);
  };

  // **Conditional Rendering Logic**
  if (isLoading) {
    return <Spinner size="large" />;
  }

  if (error) {
    return <p className="error-message">{error.data?.error}</p>;
  }

  if (!data || data.conversations.length === 0) {
    // console.warn("📭 No conversations to display.");
    return null;
  }

  return (
    <div className="messaging-sidebar-container">
      <div className="profile-list">
        <ConversationList
          conversations={data.conversations}
          onSelectConversation={handleSelectConversation}
          selectedConversation={selectedConversation}
          // notifications={notifications}
        />
      </div>
      <AnimatePresence>
        {selectedConversation && currentUser && (
          <motion.div
            className="expanded-sidebar"
            initial={{ width: 0, opacity: 0 }}
            exit={{ width: 0, opacity: 0 }}
            animate={{ width: "40vh", opacity: 1 }}
          >
            <ActiveConversation
              conversation={selectedConversation}
              currentUserName={currentUser.name}
              currentUserPhoto={currentUser.photoUrl}
              onCloseConversation={handleCloseConversation}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Sidebar;
