
import { getCurrentUser } from './Cognito';
import AWS from 'aws-sdk';
import config from './config';


export const fetchCurrentUser = async () => {
  return new Promise((resolve) => {
    getCurrentUser((attributes) => {
      const email = attributes.find(attr => attr.Name === 'email')?.Value || null;
      resolve(email);
    });
  });
};

export const fetchUserProfile = async (userId) => {
  AWS.config.region = "us-west-1";
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.aws.identityPoolId, // Use IdentityPoolId from config
  });

  const dynamodb = new AWS.DynamoDB.DocumentClient();
  const params = {
    TableName: config.aws.usersTableName, 
    Key: {
      cognitoId: userId,
    },
  };

  try {
    const result = await dynamodb.get(params).promise();
    return result.Item;
  } catch (error) {
    console.error("Error fetching user profile:", error);
    return null;
  }
};

