import React from "react";
import Spinner from "./Spinner";
import "../styles/Sidebar.css";
import { useCurrentUser } from "../utils/APIHooks";
import cx from "classnames";

const ConversationList = ({
  conversations,
  onSelectConversation,
  selectedConversation,
  isLoading,
  error,
}) => {
  const currentUser = useCurrentUser();
  return (
    <div className="conversation-list">
      {error && <p className="error-message">{error}</p>}

      {isLoading ? (
        <Spinner size="medium" />
      ) : conversations.length > 0 ? (
        conversations.map((conversation) => (
          <div
            key={conversation.convoId}
            className={cx("conversation-item relative", {
              "bg-gray-300":
                conversation.convoId === selectedConversation?.convoId,
            })}
            onClick={() => onSelectConversation(conversation)}
          >
            <img
              src={conversation.photoUrl || "https://via.placeholder.com/40"}
              alt={`${conversation.name}'s avatar`}
              className="conversation-avatar"
            />
            {!conversation.lastMessageRead &&
              conversation.lastSender !== currentUser.cognitoId &&
              conversation.convoId !== selectedConversation?.convoId && (
                <div className="notification-badge absolute top-0 right-0">!</div>
              )}
          </div>
        ))
      ) : (
        <p className="empty-message">You have no conversations yet.</p>
      )}
    </div>
  );
};

export default ConversationList;
