import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Spinner from "./Spinner";
import { useChatSocket } from "../utils/chatSocketUtil";
import { useCurrentUser } from "../utils/APIHooks";
import { useGetMessagesQuery, useMarkAsReadMutation } from "../utils/APIUtil";
import { fetchUserProfile } from "../authUtils";
import config from "../config";

const ActiveConversation = ({
  conversation,
  onNewMessage,
  currentUserName,
  currentUserPhoto,
  onCloseConversation,
}) => {
  const [messages, setMessages] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null);
  const currentUser = useCurrentUser();
  const {
    data: messageData,
    isLoading,
    isFetching,
  } = useGetMessagesQuery(conversation.convoId, {
    skip: !conversation.convoId || currentUser == null,
  });
  const [markAsRead] = useMarkAsReadMutation();
  const { sendMessage, readyState } = useChatSocket(
    currentUser?.cognitoId,
    useCallback(
      (event) => {
        const response = JSON.parse(event.data);
        const { data, action } = response;

        switch (action) {
          case "chat_update": {
            if (data.convoId === conversation.convoId) {
              console.log("chat update");
              setMessages((prev) => [...prev, data]);
              // should invalidate the message cache when this window exits
              // setInvalidateOnExit(true);
            }
            break;
          }
          default:
            break;
        }
      },
      [setMessages, conversation.convoId]
    )
  );

  const isConnected = useMemo(
    () => readyState === WebSocket.OPEN,
    [readyState]
  );
  

  useEffect(() => {
    if (messageData) {
      setMessages(messageData.messages);
    }
  }, [messageData]);

  // Scroll to the latest message when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  }, [messages, isFetching, isLoading]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const messageDate = formatDate(message.timestamp);
      if (!groups[messageDate]) {
        groups[messageDate] = [];
      }
      groups[messageDate].push(message);
      return groups;
    }, {});
  };

  const sendBrevoEmail = async ({ recipientEmail }) => {
    try {
      const response = await fetch("https://api.brevo.com/v3/smtp/email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json",
          "api-key": config.brevo.apiKey,
        },
        body: JSON.stringify({
          sender: { name: "PressPlay Notifications", email: "hello@pressplay.gg" },
          to: [{ email: recipientEmail }],
          templateId: 1
        }),
      });
  
      if (!response.ok) throw new Error("Failed to send email");
      console.log("Notification email sent successfully!");
    } catch (error) {
      console.error("Error sending Brevo email:", error);
    }
  };
  

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !isConnected || !currentUser?.cognitoId) return;

    setIsSending(true);
    const message = {
      action: "chat",
      convoId: conversation.convoId,
      senderId: currentUser.cognitoId,
      recipientId: conversation.userId,
      message: newMessage,
    };

    try {

      const recipientProfile = await fetchUserProfile(conversation.userId);
      if (recipientProfile.email) {
        sendBrevoEmail({
          recipientEmail: recipientProfile.email,
        });
      }
      sendMessage(message);
      setNewMessage("");
    } catch (error) {
      console.error("Failed to send message via WebSocket:", error);
      alert("Failed to send message. Check your connection.");
    }
    finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    if (
      conversation &&
      conversation.lastSender !== currentUser?.cognitoId &&
      !conversation.lastMessageRead
    ) {
      // mark as read on open
      markAsRead(conversation.convoId);
    }
  }, [conversation]);

  return (
    <div className="chat-window">
      {conversation.convoId ? (
        isFetching ? (
          <Spinner />
        ) : (
          <>
            <div className="chat-header">
              <h4>Chat with {conversation.name}</h4>
              <button className="close-button" onClick={onCloseConversation}>
                X
              </button>
            </div>
            <div className="chat-messages">
              {Object.entries(groupMessagesByDate(messages)).map(
                ([date, messagesForDate]) => (
                  <div key={date} className="message-group">
                    <div className="date-divider">{date}</div>
                    {messagesForDate.map((message, index) => (
                      <div
                        key={index}
                        className={`chat-message ${
                          message.senderId === currentUser?.cognitoId
                            ? "chat-sent"
                            : "chat-received"
                        }`}
                      >
                        <img
                          src={
                            message.senderId === currentUser?.cognitoId
                              ? currentUserPhoto
                              : conversation.photoUrl
                          }
                          alt={`${
                            message.senderId === currentUser?.cognitoId
                              ? currentUserName
                              : conversation.name
                          }'s profile`}
                          className="chat-message-profile"
                        />
                        <div className="chat-message-content">
                          <div className="chat-message-header">
                            <span className="chat-message-sender">
                              {message.senderId === currentUser?.cognitoId
                                ? currentUserName
                                : conversation.name}
                            </span>
                            <span className="chat-message-timestamp">
                              {formatTimestamp(message.timestamp)}
                            </span>
                          </div>
                          <div className="chat-message-text">
                            {message.message}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              )}
              <div ref={messagesEndRef} />
            </div>
            <div className="message-input-container">
              <textarea
                className="message-input"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message..."
              ></textarea>
            <button className="send-button" onClick={handleSendMessage} disabled={isSending}>
              {isSending ? <Spinner /> : "Send"}
            </button>
            </div>
          </>
        )
      ) : (
        <p>No Messages</p>
      )}
    </div>
  );
};

export default ActiveConversation;
